const zhTW= {
    login: {
      title: '登入',
      sign_in_btn: '登入'
    },
    bar: {
        logout: '登出',
        languages: '語言',
        settings: '開啟設定'
    },
    language: {
      name: '中文（臺灣）',
    },
    attributePage: {
        title: '屬性變更',
        viewChanges: '查看變更',
        dateTime: '日期',
        notificationType: '通知類型',
        notificationValue: '通知值',
        oldValue: '舊值',
        newValue: '新值',
        accept: '接受',
        reject: '拒絕',
    },
    Home: '首頁',
    load: '執行初始載入中...',
    Requested: "已要求",
    Viewed: "已檢視",
    Executing: "執行中",
    Completed: "已完成",
    Expired: "已過期",
    Cancelled: "已取消",
    Error: "錯誤",
    Rejected: "已拒絕",
    error: '遇到問題，請稍後再試一次。',
    displayname: '顯示名稱',
    givenname: '名字',
    surname: '姓氏',
    email: '電子郵件',
    mail: '電子郵件',
    name: '名字',
    firstname: '名字',
    lastname: '姓氏',
    footer_copyright: '保留所有權利。'
  };
  
  export default zhTW;
  