const xhZA= {
    login: {
      title: 'Sayina ungene',
      sign_in_btn: 'Sayina ungene'
    },
    bar: {
        logout: 'Loga uphume',
        languages: 'Iilwimi',
        settings: 'Vula useto'
    },
    language: {
      name: 'IsiNgesi (saseUS)',
    },
    attributePage: {
        title: 'Cacisa Utshintsho',
        viewChanges: 'Bona Utshintsho',
        dateTime: 'Umhla',
        notificationType: 'Uhlobo Lwesaziso',
        notificationValue: 'Ixabiso Lwesaziso',
        oldValue: 'Ixabiso Elidala',
        newValue: 'Ixabiso Elitsha',
        accept: 'Yamkela',
        reject: 'Yala',
    },
    Home: 'Ekhaya',
    load: 'Ukwenziwa kolayisho lwe-inishiyali...',
    Requested: "Iceliwe",
    Viewed: "Ijongiwe",
    Executing: "Iyaqala",
    Completed: "Igqityiwe",
    Expired: "Iphelelwe",
    Cancelled: "Irhoxisiwe",
    Error: "Impazamo",
    Rejected: "Yaliwe",
    error: 'Kukho okuhambanga kakuhle; nceda uzame kwakhona kamva.',
    displayname: 'Bonisa Igama',
    givenname: 'Igama',
    surname: 'Ifani',
    email: 'I-imeyile',
    mail: 'I-imeyile',
    name: 'Igama',
    firstname: 'Igama',
    lastname: 'Ifani',
    footer_copyright: 'Onke amalungelo agciniwe.'
  };
  
  export default xhZA;
  