import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// utils
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { defaultLang } from './config-lang';
//
import afZALocales from './langs/af-ZA';
import arILLocales from './langs/ar-IL';
import bgBGLocales from './langs/bg-BG';
import deATLocales from './langs/de-AT';
import deBELocales from './langs/de-BE';
import deDELocales from './langs/de-DE';
import enAULocales from './langs/en-AU';
import enCALocales from './langs/en-CA';
import enGBLocales from './langs/en-GB';
import enIELocales from './langs/en-IE';
import enNZLocales from './langs/en-NZ';
import enUSLocales from './langs/en-US';
import esARLocales from './langs/es-AR';
import esESLocales from './langs/es-ES';
import esMXLocales from './langs/es-MX';
import esPRLocales from './langs/es-PR';
import esUSLocales from './langs/es-US';
import fiFILocales from './langs/fi-FI';
import frBELocales from './langs/fr-BE';
import frCALocales from './langs/fr-CA';
import frFRLocales from './langs/fr-FR';
import heILLocales from './langs/he-IL';
import itITLocales from './langs/it-IT';
import jaJPLocales from './langs/ja-JP';
import koKRLocales from './langs/ko-KR';
import nlBELocales from './langs/nl-BE';
import nlNLLocales from './langs/nl-NL';
import plPLLocales from './langs/pl-PL';
import ptBRLocales from './langs/pt-BR';
import ptPTLocales from './langs/pt-PT';
import srRSLocales from './langs/sr-RS';
import svFILocales from './langs/sv-FI';
import svSELocales from './langs/sv-SE';
import xhZALocales from './langs/xh-ZA';
import zhTWLocales from './langs/zh-TW';
import zuZALocales from './langs/zu-ZA';



// ----------------------------------------------------------------------

let lng = defaultLang.value;
let resources = {
  'af-ZA': { translations: afZALocales },
  'ar-IL': { translations: arILLocales },
  'bg-BG': { translations: bgBGLocales },
  'de-AT': { translations: deATLocales },
  'de-BE': { translations: deBELocales },
  'de-DE': { translations: deDELocales },
  'en-AU': { translations: enAULocales },
  'en-CA': { translations: enCALocales },
  'en-GB': { translations: enGBLocales },
  'en-IE': { translations: enIELocales },
  'en-NZ': { translations: enNZLocales },
  'en-US': { translations: enUSLocales },
  'es-AR': { translations: esARLocales },
  'es-ES': { translations: esESLocales },
  'es-MX': { translations: esMXLocales },
  'es-PR': { translations: esPRLocales },
  'es-US': { translations: esUSLocales },
  'fi-FI': { translations: fiFILocales },
  'fr-BE': { translations: frBELocales },
  'fr-CA': { translations: frCALocales },
  'fr-FR': { translations: frFRLocales },
  'he-IL': { translations: heILLocales },
  'it-IT': { translations: itITLocales },
  'ja-JP': { translations: jaJPLocales },
  'ko-KR': { translations: koKRLocales },
  'nl-BE': { translations: nlBELocales },
  'nl-NL': { translations: nlNLLocales },
  'pl-PL': { translations: plPLLocales },
  'pt-BR': { translations: ptBRLocales },
  'pt-PT': { translations: ptPTLocales },
  'sr-RS': { translations: srRSLocales },
  'sv-FI': { translations: svFILocales },
  'sv-SE': { translations: svSELocales },
  'xh-ZA': { translations: xhZALocales },
  'zh-TW': { translations: zhTWLocales },
  'af': { translations: afZALocales },
  'ar': { translations: arILLocales },
  'bg': { translations: bgBGLocales },
  'de': { translations: deBELocales },
  'en': { translations: enUSLocales },
  'es': { translations: esUSLocales },
  'fi': { translations: fiFILocales },
  'fr': { translations: frFRLocales },
  'he': { translations: heILLocales },
  'it': { translations: itITLocales },
  'ja': { translations: jaJPLocales },
  'ko': { translations: koKRLocales },
  'nl': { translations: nlNLLocales },
  'pl': { translations: plPLLocales },
  'pt': { translations: ptPTLocales },
  'sr': { translations: srRSLocales },
  'sv': { translations: svSELocales },
  'xh': { translations: xhZALocales },
  'zh': { translations: zhTWLocales }
};
const storageAvailable = localStorageAvailable();

if (storageAvailable) {
  lng = localStorage.getItem('i18nextLng') || defaultLang.value;
}

const urlParams = new URLSearchParams(window.location.search);
let urlLang = urlParams.get('lang');

if (urlLang && Object.keys(resources).includes(urlLang)) {
  lng = urlLang;
  localStorage.setItem('i18nextLng', lng);
 }

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      'af-ZA': { translations: afZALocales },
      'ar-IL': { translations: arILLocales },
      'bg-BG': { translations: bgBGLocales },
      'de-AT': { translations: deATLocales },
      'de-BE': { translations: deBELocales },
      'de-DE': { translations: deDELocales },
      'en-AU': { translations: enAULocales },
      'en-CA': { translations: enCALocales },
      'en-GB': { translations: enGBLocales },
      'en-IE': { translations: enIELocales },
      'en-NZ': { translations: enNZLocales },
      'en-US': { translations: enUSLocales },
      'es-AR': { translations: esARLocales },
      'es-ES': { translations: esESLocales },
      'es-MX': { translations: esMXLocales },
      'es-PR': { translations: esPRLocales },
      'es-US': { translations: esUSLocales },
      'fi-FI': { translations: fiFILocales },
      'fr-BE': { translations: frBELocales },
      'fr-CA': { translations: frCALocales },
      'fr-FR': { translations: frFRLocales },
      'he-IL': { translations: heILLocales },
      'it-IT': { translations: itITLocales },
      'ja-JP': { translations: jaJPLocales },
      'ko-KR': { translations: koKRLocales },
      'nl-BE': { translations: nlBELocales },
      'nl-NL': { translations: nlNLLocales },
      'pl-PL': { translations: plPLLocales },
      'pt-BR': { translations: ptBRLocales },
      'pt-PT': { translations: ptPTLocales },
      'sr-RS': { translations: srRSLocales },
      'sv-FI': { translations: svFILocales },
      'sv-SE': { translations: svSELocales },
      'xh-ZA': { translations: xhZALocales },
      'zh-TW': { translations: zhTWLocales },
      'zu-ZA': { translations: zuZALocales },
      'af': { translations: afZALocales },
      'ar': { translations: arILLocales },
      'bg': { translations: bgBGLocales },
      'de': { translations: deBELocales },
      'en': { translations: enUSLocales },
      'es': { translations: esUSLocales },
      'fi': { translations: fiFILocales },
      'fr': { translations: frFRLocales },
      'he': { translations: heILLocales },
      'it': { translations: itITLocales },
      'ja': { translations: jaJPLocales },
      'ko': { translations: koKRLocales },
      'nl': { translations: nlNLLocales },
      'pl': { translations: plPLLocales },
      'pt': { translations: ptPTLocales },
      'sr': { translations: srRSLocales },
      'sv': { translations: svSELocales },
      'xh': { translations: xhZALocales },
      'zh': { translations: zhTWLocales },
      'zu': { translations: zuZALocales },
    },
    lng,
    fallbackLng: defaultLang.value,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
