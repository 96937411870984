const afZA= {
    login: {
      title: 'Teken in',
      sign_in_btn: 'Teken in'
    },
    bar: {
        logout: 'Meld af',
        languages: 'Tale',
        settings: 'Maak Instellings oop'
    },
    language: {
      name: 'Afrikaans (Suid-Afrika)',
    },
    attributePage: {
        title: 'Veranderinge aan eienskappe',
        viewChanges: 'Sien veranderinge.',
        dateTime: 'Datum',
        notificationType: 'Kennisgewingtipe',
        notificationValue: 'Kennisgewingwaarde',
        oldValue: 'Ou waarde',
        newValue: 'Nuwe waarde',
        accept: 'Aanvaar',
        reject: 'Verwerp',
    },
    Home: 'Tuisblad',
    load: 'Voer tans aanvanklike laai uit …',
    Requested: "Aangevra",
    Viewed: "Besigtig",
    Executing: "Voer tans uit.",
    Completed: "Voltooid",
    Expired: "Verval",
    Cancelled: "Gekanselleer",
    Error: "Fout",
    Rejected: "Verwerp",
    error: 'Iets het skeefgeloop; probeer asseblief later weer.',
    displayname: 'Vertoonnaam',
    givenname: 'Naam',
    surname: 'Van',
    email: 'E-pos',
    mail: 'E-pos',
    name: 'Naam',
    firstname: 'Naam',
    lastname: 'Van',
    footer_copyright: 'Alle regte voorbehou.'
  };
  
  export default afZA;
  