const fiFI= {
    login: {
      title: 'Kirjaudu sisään',
      sign_in_btn: 'Kirjaudu sisään'
    },
    bar: {
        logout: 'Kirjaudu ulos',
        languages: 'Kielet',
        settings: 'Avaa asetukset'
    },
    language: {
      name: 'Finnish (FI)',
    },
    attributePage: {
        title: 'Tietojen muutokset',
        viewChanges: 'Katso muutokset',
        dateTime: 'Päivämäärä',
        notificationType: 'Ilmoituksen tyyppi',
        notificationValue: 'Ilmoituksen arvo',
        oldValue: 'Vanha arvo',
        newValue: 'Uusi arvo',
        accept: 'Hyväksy',
        reject: 'Hylkää',
    },
    Home: 'Etusivu',
    load: 'Suoritetaan alustavaa latausta...',
    Requested: "Pyydetty",
    Viewed: "Katsottu",
    Executing: "Suoritetaan",
    Completed: "Valmis",
    Expired: "Vanhentunut",
    Cancelled: "Peruttu",
    Error: "Virhe",
    Rejected: "Hylätty",
    error: 'Jokin meni vikaan, yritä myöhemmin uudelleen.',
    displayname: 'Näyttönimi',
    givenname: 'Nimi',
    surname: 'Sukunimi',
    email: 'Sähköposti',
    mail: 'Sähköposti',
    name: 'Nimi',
    firstname: 'Nimi',
    lastname: 'Sukunimi',
    footer_copyright: 'Kaikki oikeudet pidätetään.'
  };
  
  export default fiFI;
  