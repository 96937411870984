const svFI= {
    login: {
      title: 'Inloggning',
      sign_in_btn: 'Inloggning'
    },
    bar: {
        logout: 'Utloggning',
        languages: 'Språk',
        settings: 'Öppna inställningar'
    },
    language: {
      name: 'Svenska (Finland)',
    },
    attributePage: {
        title: 'Ändring av egenskaper',
        viewChanges: 'Se ändringar',
        dateTime: 'Datum',
        notificationType: 'Typ av avisering',
        notificationValue: 'Aviseringsnummer',
        oldValue: 'Gammalt värde',
        newValue: 'Nytt värde',
        accept: 'Godkänna',
        reject: 'Avvisa',
    },
    Home: 'Startsida',
    load: 'Utför initial laddning...',
    Requested: "Begärd",
    Viewed: "Visad",
    Executing: "Utför",
    Completed: "Slutförd",
    Expired: "Har slutat gälla",
    Cancelled: "Avbruten",
    Error: "Fel",
    Rejected: "Avvisad",
    error: 'Något gick felFörsök igen senare.',
    displayname: 'Visningsnamn',
    givenname: 'Namn',
    surname: 'Efternamn',
    email: 'E-postadress',
    mail: 'E-postadress',
    name: 'Namn',
    firstname: 'Namn',
    lastname: 'Efternamn',
    footer_copyright: 'Alla rättigheter förbehålles.'
  };
  
  export default svFI;
  