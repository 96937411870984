const zuZA= {
    login: {
      title: 'Ngena ngemvume',
      sign_in_btn: 'Ngena ngemvume'
    },
    bar: {
        logout: 'Zikhiphe',
        languages: 'Izilimi',
        settings: 'Vula izilungiselelo'
    },
    language: {
      name: 'Zulu',
    },
    attributePage: {
        title: 'Izinguquko Zezimfanelo',
        viewChanges: 'Bona Izinguquko',
        dateTime: 'Usuku',
        notificationType: 'Uhlobo Lwesaziso',
        notificationValue: 'Inani Lesaziso',
        oldValue: 'Inani Elidala',
        newValue: 'Inani Elisha',
        accept: 'Yamukela',
        reject: 'Yenqaba',
    },
    Home: 'Ikhaya',
    load: 'Ukwenza ukulowuda kokuqala...',
    Requested: "Iceliwe",
    Viewed: "Okubukiwe",
    Executing: "Iyasebenzisa",
    Completed: "Kuqediwe",
    Expired: "Iphelelwe yisikhathi",
    Cancelled: "Kukhanselwe",
    Error: "Iphutha",
    Rejected: "Kwenqatshiwe",
    error: 'Kukhona okungahambanga kahle; sicela uzame futhi kamuva.',
    displayname: 'Igama Eliboniswayo',
    givenname: 'Igama',
    surname: 'Isibongo',
    email: 'I-Imeyili',
    mail: 'I-Imeyili',
    name: 'Igama',
    firstname: 'Igama',
    lastname: 'Isibongo',
    footer_copyright: 'Wonke amalungelo agodliwe.'
  };
  
  export default zuZA;
  